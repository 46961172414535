import React from "react"
// import { Link } from "gatsby"
import { Column, Container, Row } from "../components/Grid"
import Hero from "../components/Hero"
import Icon from "../components/Icon"
import Layout from "../components/Layout"
import Modal from "../components/Modal"
import Section from "../components/Section"
import SEO from "../components/Seo"
import Video from "../components/Video"
// import DayOfLearning from "../images/theme/day-of-learning@2x.jpg"
import SubFooterImage from "../images/theme/img-subfooter-community.jpg"
// import Image from "../components/Image";

const CommunityPage = () => (
  <Layout>
    <SEO title="Community" />
    {/***************  HERO ***************/}
    <Section className="hero hero--community">
      <Hero
        title="Community"
        infographic="img-hero-infographic-community.png"
        body="Spark proactive substance misuse prevention discussions in your community with no-cost resources."
        image="img-hero-community.jpg"
      />
    </Section>

    {/***************  QUICK LINKS ***************/}
    <Section className="midgray-background">
      <Container>
        <Row>
          <Column span={12}>
            <div className="community__quick-links">
              <strong>Quick Links:</strong>
              <a href={"#2021-red-ribbon-week"}>2021 Red Ribbon Week Panel</a>
              <a href={"#nida"}>National Drug and Alcohol Facts Week Panel</a>
              <a href={"#register"}>2020 Red Ribbon Week Panel</a>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>

    {/*************** 2021 Red Ribbon Week Panel Discussion  ***************/}
    <Section className="midgray-background" id="2021-red-ribbon-week">
      <Container>
        <Row className="white-background pd-1">
          <Column span={5}>
            <h3>2021 Red Ribbon Week Panel Discussion</h3>
            <p>
              Join CVS Pharmacist, Tyler Davis, and Discovery Education’s Emily
              Wirth, and Jackie Kavege as they discuss the role the community
              can play in helping students make healthy decisions. Learn how
              educators, parents, school, and community leaders can use Dose of
              Knowledge resources to help start critical conversations in
              students 3-12 grade.
            </p>
            <Modal
              trigger={<p className="global__button red">Watch Now</p>}
              video
            >
              <div className="video">
                <div className="video__player">
                  <Video guidSrc="2eb03aab-afc5-4a29-83fb-a90e8b9c8a87" />
                </div>
              </div>
            </Modal>
            <p>
              <a
                className={"twitter-callout"}
                href="https://twitter.com/intent/tweet?text=Empower%20students%20to%20make%20good%20decisions%20around%20substance%20misuse%20for%20the%20health%20and%20wellbeing%20of%20themselves%20and%20their%20community%20with%20%23DoseofKnowledge%20by%20@DiscoveryEd%20and%20@CVSHealth%20www.DoseofKnowledge.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name={"twitter"} inline={true} />
                <strong>#DoseOfKnowledge</strong>
              </a>
            </p>
          </Column>
          <Column span={7}>
            <Video
              imgPlaceholder="img-community-2021-red-ribbon.png"
              // imgPlaceholder="img-community-dol-seminar.png"
              guidSrc="2eb03aab-afc5-4a29-83fb-a90e8b9c8a87"
              // posterSrc={SubFooterImage}
            />
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  NIDA ***************/}
    <Section className="midgray-background" id="nida">
      <Container>
        <Row className="white-background pd-1">
          <Column span={5}>
            <h3>National Drug and Alcohol Facts Week - Panel Discussion</h3>
            <p>
              Join this conversation around the science of substance misuse. The
              panel features perspectives from, Dr. Brenda Curtis, a NIDA
              scientist, Terra Jones, a CVS pharmacist, and Palmer Williams, a
              first-year college student at Georgia State University
            </p>
            <Modal
              trigger={<p className="global__button red">Watch Now</p>}
              video
            >
              <div className="video">
                <div className="video__player">
                  <Video
                    // imgPlaceholder="day-of-learning@2x.jpg"
                    guidSrc="bb20c8b9-aa75-46f1-9679-32775a593508"
                    // posterSrc={SubFooterImage}
                  />
                </div>
              </div>
            </Modal>
            <p>
              <a
                className={"twitter-callout"}
                href="https://twitter.com/intent/tweet?text=Empower%20students%20to%20make%20good%20decisions%20around%20substance%20misuse%20for%20the%20health%20and%20wellbeing%20of%20themselves%20and%20their%20community%20with%20%23DoseofKnowledge%20by%20@DiscoveryEd%20and%20@CVSHealth%20www.DoseofKnowledge.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name={"twitter"} inline={true} />
                <strong>#DoseOfKnowledge</strong>
              </a>
            </p>
          </Column>
          <Column span={7}>
            <Video
              imgPlaceholder="NIDA.png"
              // imgPlaceholder="img-community-dol-seminar.png"
              guidSrc="bb20c8b9-aa75-46f1-9679-32775a593508"
              // posterSrc={SubFooterImage}
            />
          </Column>
        </Row>
      </Container>
    </Section>

    {/*************** 2020 DAY OF LEARNING ***************/}
    <Section className="midgray-background" id="register">
      <Container>
        <Row className="white-background pd-1">
          <Column span={5} className="day__learning__left">
            <div className="white-background">
              <h3>2020 Red Ribbon Week – Panel Discussion</h3>
              <p>
                Engage students in healthy discussions around the importance of
                preventing opioid and prescription medication misuse, with
                subject matter experts and special guests. The discussion
                participants include; Will Ward, from Discovery Education,
                Michelle Osborn-Hallet, Middle School Educator, Dr. Brenda
                Curtis, a NIDA scientist, Terra Jones, a CVS pharmacist, and a
                Palmer Williams, a student at Georgia State University.
              </p>
              <Modal
                trigger={<p className="global__button red">Watch Now</p>}
                video
              >
                <div className="video">
                  <div className="video__player">
                    <Video
                      // imgPlaceholder="day-of-learning@2x.jpg"
                      guidSrc="9e98a5b6-c9e0-4214-a223-fd858b3ee589"
                      // posterSrc={SubFooterImage}
                    />
                  </div>
                </div>
              </Modal>
              <p>
                <a
                  className={"twitter-callout"}
                  href="https://twitter.com/intent/tweet?text=Empower%20students%20to%20make%20good%20decisions%20around%20substance%20misuse%20for%20the%20health%20and%20wellbeing%20of%20themselves%20and%20their%20community%20with%20%23DoseofKnowledge%20by%20@DiscoveryEd%20and%20@CVSHealth%20www.DoseofKnowledge.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name={"twitter"} inline={true} />
                  <strong>#DoseOfKnowledge</strong>
                </a>
              </p>
            </div>
          </Column>
          <Column span={7} className="day__learning__right">
            <Video
              imgPlaceholder="day-of-learning@2x.jpg"
              // imgPlaceholder="img-community-dol-seminar.png"
              guidSrc="9e98a5b6-c9e0-4214-a223-fd858b3ee589"
              // posterSrc={SubFooterImage}
            />
          </Column>
        </Row>
      </Container>
    </Section>

    {/*/!***************  TWO-COLUMN AGENDA/TWITTER CALLOUT ***************!/*/}
    {/*<Section className='midgray-background' id='agenda'>*/}
    {/*  <Container>*/}
    {/*    <Row>*/}
    {/*      <Column span={6}>*/}
    {/*        <div className={'white-background curriculum__agenda'}>*/}
    {/*          <h3>Agenda</h3>*/}
    {/*          /!*<p>*!/*/}
    {/*          */}
    {/*          /!*</p>*!/*/}
    {/*          <p><strong>October 27, 2020</strong></p>*/}
    {/*          <p><strong>11-11:45am ET</strong> Engage students in healthy discussions around the importance of preventing opioid and prescription medication misuse, with subject matter experts and special guests. The discussion will be broadcast live on the Discovery Education Facebook page, and will be available on-demand.</p>*/}
    {/*          <p><strong>2:00pm ET</strong> Join us on Twitter for an interactive chat to learn more about the new resources available to support opioid misuse education in classrooms, homes and communities. You can join the conversation all day long on Twitter using #DoseofKnowledge.</p>*/}
    {/*          <Modal*/}
    {/*            className={'day__learning__modal'}*/}
    {/*            trigger={*/}
    {/*              <p  className="global__button red">*/}
    {/*                Register Now*/}
    {/*              </p>*/}
    {/*            }*/}
    {/*          >*/}
    {/*            <iframe*/}
    {/*              frameBorder='none'*/}
    {/*              src={'https://form.jotform.com/202395256836159'}*/}
    {/*              title={'Jotformregistration'}*/}
    {/*            />*/}
    {/*          </Modal>*/}
    {/*          <div className='day__learning__agenda__image'>*/}
    {/*            <Image*/}
    {/*              */}
    {/*              filename='graphic-illustration@2x.png'*/}
    {/*            />*/}
    {/*            <a*/}
    {/*              className={'twitter-callout'}*/}
    {/*              href='https://twitter.com/intent/tweet?text=Empower%20students%20to%20make%20good%20decisions%20around%20substance%20misuse%20for%20the%20health%20and%20wellbeing%20of%20themselves%20and%20their%20community%20with%20%23DoseofKnowledge%20by%20@DiscoveryEd%20and%20@CVSHealth%20www.DoseofKnowledge.com'*/}
    {/*              target='_blank'*/}
    {/*              rel='noopener noreferrer'*/}
    {/*            >*/}
    {/*              <Icon*/}
    {/*                name={'twitter'}*/}
    {/*                inline={true}*/}
    {/*              />*/}
    {/*              <strong>#DoseOfKnowledge</strong>*/}
    {/*            </a>*/}
    {/*          </div>*/}
    {/*        </div>*/}
    {/*      </Column>*/}
    {/*      <Column span={6}>*/}
    {/*        <div className={'white-background curriculum__twitter-embed'} id='share'>*/}
    {/*          <h3>ART of <em>AWARENESS</em></h3>*/}
    {/*          <a*/}
    {/*            className={'twitter-callout'}*/}
    {/*            href='https://twitter.com/intent/tweet?text=Empower%20students%20to%20make%20good%20decisions%20around%20substance%20misuse%20for%20the%20health%20and%20wellbeing%20of%20themselves%20and%20their%20community%20with%20%23DoseofKnowledge%20by%20@DiscoveryEd%20and%20@CVSHealth%20www.DoseofKnowledge.com'*/}
    {/*            target='_blank'*/}
    {/*            rel='noopener noreferrer'*/}
    {/*            */}
    {/*          >*/}
    {/*            <Icon*/}
    {/*              name={'twitter'}*/}
    {/*              inline={true}*/}
    {/*            />*/}
    {/*            <strong>#DoseOfKnowledge</strong>*/}
    {/*          </a>*/}
    {/*          <p>*/}
    {/*            Critical discussions about healthy decision-making start with honest expressions! Students can help lead the way during Red Ribbon Week by submitting a picture, digital poster, poem, blog, painting or other original work of art sharing their own experiences related to substance misuse prevention. Creative expressions could include:*/}
    {/*          </p>*/}
    {/*          <ul>*/}
    {/*            <li>A poem about managing stress</li>*/}
    {/*            <li>A video demonstrating responses to peer pressure</li>*/}
    {/*            <li>A comic strip depicting a triumph over addiction</li>*/}
    {/*            <li>A song inspiring youth to make healthy choices</li>*/}
    {/*            <li>A blog highlighting the impact of substance misuse on the brain</li>*/}
    {/*          </ul>*/}
    {/*          <p>*/}
    {/*            Use #DoseOfKnowledge to share students’ expressions on Twitter and it could be featured here!*/}
    {/*          </p>*/}
    {/*          /!*<a className="twitter-timeline" data-width="400" data-height="400"*!/*/}
    {/*          /!*   href="https://twitter.com/colorhive/timelines/1298601457915240454?ref_src=twsrc%5Etfw">Red Ribbon Week*!/*/}
    {/*          /!*  - Curated tweets by colorhive</a>*!/*/}
    {/*          /!*<script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>*!/*/}
    {/*        </div>*/}
    {/*      </Column>*/}
    {/*    </Row>*/}
    {/*  </Container>*/}
    {/*</Section>*/}

    {/***************  MEET THE PANELISTS ***************/}
    {/*
    <Section className='midgray-background' id='panelists'>
     <Container>
       <div className='curriculum__panelist-header'>
         <h3>Meet the Panelists</h3>
         <p>
          Our Day of Learning guest speakers each offer unique real-world insights into the prevention of opioid and prescription medication misuse in schools and communities. Learn new ways to support your students’ wellness by tuning in for this one-of-a-kind sharing session.
         </p>
       </div>
       <Row>
         <Panelists panelists={[
            {
              image: 'headshots/Will_Ward.png',
              name: 'WILL WARD',
              title: 'Discovery Education',
              description: 'Will Ward has a Bachelor of Arts in Organizational Communication with an emphasis in Marketing from Queens University of Charlotte. For nearly a decade, he has partnered with state and local government agencies to enhance K-12 school districts and higher education institutions. He currently works with Discovery Education to help transform education by leveraging dynamic digital instructional content throughout the country.'
            },
            {
              image: 'headshots/Michelle_Osborn_Hallet.png',
              name: 'MICHELLE OSBORN-HALLET',
              title: 'Middle School Teacher at East Stroudsburg School District',
              description: 'Michelle Osborn-Hallet is a 32-year veteran Classroom teacher of middle school science for the East Stroudsburg School District in East Stroudsburg Pennsylvania. She is an advisor and conference presenter for Sigma Sigma Sigma National Sorority, a member of Discovery Education’s Den Ambassadors, and a 2019 Denny Award Winner. Michelle believe it is her duty to share her time and talents with others in the hope that they will then do the same, and to help them develop an enjoyment for learning and exploring their passions. In her free time, Michelle likes to ski, read, and can often be found enjoying her backyard and honey bees.'
            },
            {
              image: 'headshots/Terra_Jones.png',
              name: 'Dr. TERRA JONES',
              title: 'CVS Pharmacist ',
              description: 'Terra Jones, PharmD, is a licensed pharmacist that currently is a CVS Health District Leader covering 13 CVS Pharmacy locations across the Columbia, South Carolina market. She received her Doctor of Pharmacy degree from the University of South Carolina in 2004 with a goal of impacting community pharmacy by bringing a highly personal experience to the retail chain pharmacy business. She began with CVS Health as an intern while in pharmacy school and moved into a Pharmacy Manager role after graduation, before being promoted to Pharmacy Supervisor and later to District Leader in 2018. In each of her positions, she has challenged her team to be relentless and transformational in delivering on the CVS purpose of helping people on their path to better health. Terra has been actively involved in the CVS Health Pharmacists Teach Program since its inception in 2015. She has a passion for youth prevention education and has personally impacted thousands of student lives, educating them on the challenges that surround substance misuse. Terra lives in Lexington, SC with her husband and 3 young children. When she’s not at work, Terra enjoys watching her boys play baseball and soccer and traveling to the beach with her family.'
            },
            {
              image: 'headshots/Brenda_Curtis.png',
              name: 'Dr. BRENDA CURTIS',
              title: 'National Institute on Drug Abuse',
              description: 'Dr. Brenda Curtis earned both a bachelor’s degree in biology and a master’s degree in public health from the University of Illinois and subsequently obtained her doctorate in communication from the University of Pennsylvania, where she most recently held the appointment of Assistant Professor of Psychology in Psychiatry, Addictions at the Perelman School of Medicine. Dr. Curtis also completed a fellowship at the Fordham University HIV and Drug Abuse Prevention Research Ethics Training Institute. Before joining NIDA IRP, she was the PI of a NIDA-funded R01 award (DA039457) entitled “Predicting AOD Relapse and Treatment Completion from Social Media Use” in which she used social media data to predict alcohol and other drug relapse and treatment completion among patients who have recently entered community outpatient treatment programs. She has also served as a co-investigator on several R01 NIAAA, NCI, and NIDA funded projects including a placebo-controlled trial of bupropion for smoking cessation in pregnant women in which we are using SMS text messaging to promote medication adherence; a multi-modal intervention on the use of a “smart” pillbox to promote medication adherence among non-adherent patients; a study examining the accuracy of smartphone breathalyzers; and a study examining the impact of a smart-phone based continuing care “app” for alcohol dependence. Her training in public health and health communication allows her to employ a public health approach while using effective communication techniques to ensure recruitment and retention rates are achieved.'
            },
            {
              image: 'headshots/Palmer_Williams.png',
              name: 'PALMER WILLIAMS III',
              title: 'Student',
              description: 'Palmer Williams, III is currently a student at Georgia State University, where he is majoring in film. His goal is to tell stories and give provide viewers with hope in the end. He plans to use his platform to educate, inform, and pass on life lessons. As he shares his stories with the world, he will strive to live by example and show those that will come after him that they can do it too.'
            },
          ]} />
       </Row>
     </Container>
    </Section>
        */}

    {/***************  JOIN THE MOVEMENT ***************/}
    {/*<Section className='midgray-background' id='join-the-movement'>*/}
    {/*  <Container>*/}
    {/*    <Row>*/}
    {/*      <Column span={6}>*/}
    {/*        <div className='white-background community__join-the-movement'>*/}
    {/*          <h3>Join the Movement</h3>*/}
    {/*          <p>RED RIBBON INSPIRES OUR KIDS TO BE HAPPY</p>*/}
    {/*          <p>*/}
    {/*            Red Ribbon Week takes place each year from October 23 through 31st.*/}
    {/*            Join us in our MISSION to help keep kids drug-free. Loriem ipsum dolor site amet ipsum in partnership with DoseOfKnowledge Loriem Ipsum dolor site amet ipsum. To learn more loriem ipsum dolor site amet. Join us October 23, 2020 here loriem ispum dolor.*/}
    {/*          </p>*/}
    {/*          */}
    {/*        </div>*/}
    {/*      </Column>*/}
    {/*      <Column>*/}
    {/*        <div className='white-background'>*/}
    {/*          /!*<Image filename='img-hero-community.jpg' />*!/*/}
    {/*        </div>*/}
    {/*      </Column>*/}
    {/*    </Row>*/}
    {/*  </Container>*/}
    {/*</Section>*/}

    {/***************  ABOVE FOOTER CALLOUT ***************/}
    <Section className="subfooter">
      <Container>
        <Row>
          <Column span={4}>
            <img
              src={SubFooterImage}
              className="subfooter-image"
              alt="Title: Facts, Info etc."
            />
          </Column>
          <Column span={8}>
            <h3>Dose of Knowledge</h3>
            <p>
              <strong>Dose of Knowledge</strong> strives to empower educators
              and pharmacists to address substance misuse and educate students
              to make good decisions for the health and wellbeing of themselves
              and their community.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
)
export default CommunityPage
